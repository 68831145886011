export const msalConfig = {
  auth: {
    clientId: "b3cbd6ec-060f-491e-b75e-d7ac0cb4cea5",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://app.sooth.fyi/signin",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
