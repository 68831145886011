import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

export const fetchSourceData = async (sourceUrl) => {
  const response = await axiosInstance.post(
    searchAPI.publisherInfo,
    {
      hostName: sourceUrl,
    },
    { cache: false }
  );
  return response.data;
};
