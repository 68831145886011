import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";
import { NewsCard } from "./news-card";
import ItemSkeleton from "../../../components/base/ItemSkeleton";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import "assets/styles/slider.css";
import useNewsData from "../hooks/use-news-data.hook";
import { useSelector } from "react-redux";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";

export default function SectionNews({ category, query }) {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const isMobile = useIsMobile();

  const { newsCards, setNewsCards } = useNewsData(setLoading, category, query);
  const { bookmarkedData } = useSelector((state) => state.data);

  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (bookmarkedData.id && newsCards?.length) {
      const bookmarkedNewsData = newsCards.map((item) => {
        if (item.url === bookmarkedData.id) {
          const itemData = {
            ...item,
            collectionSourceId: bookmarkedData.collectionSourceId,
            collectionId: bookmarkedData.collectionId,
            isCitationAvailable: bookmarkedData.isCitationAvailable,
            isSummaryAvailable: bookmarkedData.isSummaryAvailable,
          };
          return itemData;
        }
        return item;
      });
      setNewsCards(bookmarkedNewsData);
    }
  }, [
    bookmarkedData.collectionId,
    bookmarkedData.collectionSourceId,
    bookmarkedData.id,
  ]);

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      });
    }
  }, [swiper]);

  if (isMobile) {
    return (
      <Stack spacing={2}>
        {loading && (
          <ItemSkeleton
            sx={{
              minHeight: "200px",
            }}
          />
        )}
        {newsCards.length &&
          newsCards?.slice(0, 3).map((newsCard, index) => (
            <React.Fragment key={newsCard.id}>
              <NewsCard category={category} query={query} data={newsCard} />
              {index !== 2 && <Divider />}{" "}
            </React.Fragment>
          ))}
      </Stack>
    );
  }

  if (!newsCards) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <Typography variant="body2">No results Found</Typography>
      </Box>
    );
  }

  return (
    <Swiper
      onSwiper={setSwiper}
      slidesPerView={3}
      spaceBetween={30}
      slidesPerGroup={3}
      loop={false}
      pagination={{
        clickable: true,
      }}
      navigation={!loading}
      modules={[Navigation]}
      className="mySwiper"
      breakpoints={{
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1072: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1700: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        2000: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
      }}
    >
      {loading
        ? Array.from(Array(5), (e, i) => {
            return (
              <SwiperSlide key={i}>
                <ItemSkeleton
                  sx={{
                    minHeight: "200px",
                  }}
                />
              </SwiperSlide>
            );
          })
        : newsCards?.map((newsCard) => (
            <SwiperSlide key={newsCard.id}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NewsCard category={category} data={newsCard} query={query} />
              </Box>
            </SwiperSlide>
          ))}
    </Swiper>
  );
}
