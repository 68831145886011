import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
  Skeleton,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useCitationStyles } from "../../hooks/useCitation";
import { useCitationData } from "../../hooks/useCitation";
import { TextField, Stack, MenuItem } from "@mui/material";
import useCitationItemSwitch from "../../hooks/useCitation/use-citation-item-switch.hook";
import useCitationGenerate from "../../hooks/useCitation/use-citation-generate.hook";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getRegularDate } from "utils/time.util";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Citation = ({
  citation,
  setCitation,
  articleData,
  setArticleData,
  selectedStyle,
  setSelectedStyle,
  authors,
  setAuthors,
  selectedBookmarkOption,
  citationLoaded,
  setCitationLoaded,
  setOriginalArticleData,
  originalArticleData,
  setDisable,
  originalAuthors,
  setOriginalAuthors,
}) => {
  const [currentItemType, setCurrentItemType] = useState("webpage");
  const [loading, setLoading] = useState(true);
  const [additionalData, setAdditionalData] = useState({});
  const [editingValue, setEditingValue] = useState("");
  const [editingField, setEditingField] = useState(null);
  const [date, setDate] = useState(null);
  const [accessDate, setAccessDate] = useState(null);
  const [authorIndex, setAuthorIndex] = useState(0);

  const theme = useTheme();

  const { itemTypes, isCitationAvailable } = useSelector((state) => state.data);

  const { citationStyles } = useCitationStyles(
    selectedStyle,
    setSelectedStyle,
    selectedBookmarkOption,
    citationLoaded
  );
  const {
    baseFields,
    fields,
    requiredFields,
    setRequiredFields,
    setFields,
    setBaseFields,
    resetArticleData,
    resetAdditionalData,
  } = useCitationData(
    setLoading,
    setArticleData,
    setCurrentItemType,
    setAuthors,
    setDate,
    setAccessDate,
    setCitation,
    setSelectedStyle,
    articleData,
    additionalData,
    setAdditionalData,
    selectedBookmarkOption,
    citationLoaded,
    setCitationLoaded,
    setOriginalArticleData,
    setOriginalAuthors
  );

  const { handleItemChange } = useCitationItemSwitch(
    setLoading,
    baseFields,
    setBaseFields,
    additionalData,
    setAdditionalData,
    articleData,
    setArticleData,
    fields,
    setFields,
    setCurrentItemType,
    setRequiredFields,
    resetArticleData,
    resetAdditionalData
  );

  const { fetchCitation, tooltipText, handleCopyClick, handleMouseLeave } =
    useCitationGenerate(
      requiredFields,
      articleData,
      setLoading,
      citation,
      setCitation,
      currentItemType,
      selectedStyle,
      authors,
      setDisable
    );

  const handleSelectStyleType = (e) => {
    setSelectedStyle(e.target.value);
  };

  const handleSelectItemType = (e) => {
    handleItemChange(e.target.value, 0);
  };

  const CitationBox = () => {
    const {palette } = useTheme();
    return (
      <Box
        sx={{
          mb: 2,
          zIndex: 1000,
        }}
      >
        <Typography variant="body3" color="grey.500">
          Generated Citation
        </Typography>
        <Typography
          sx={{
            marginTop: "1",
            maxHeight: "15vh",
            overflowY: "auto",
            overflowX: "hidden",
            p: 1,
          }}
          variant="subtitle2"
          dangerouslySetInnerHTML={{ __html: citation }}
        />
        <Tooltip title={tooltipText} arrow>
          <Button
            startIcon={<ContentCopyIcon />}
            sx={{ mt: "1", px: 2, py: 1 }}
            variant="outlined"
            onClick={handleCopyClick}
            onMouseLeave={handleMouseLeave}
          >
            {" "}
            Copy{" "}
          </Button>
        </Tooltip>
      </Box>
    );
  };

  const renderFieldValue = (field) => {
    if (editingField === field) {
      return editingValue;
    } else
      switch (field) {
        case "date":
          return getRegularDate(articleData[field]);
        case "accessDate":
          return getRegularDate(articleData[field]);
        default:
          return articleData[field];
      }
  };

  const handleBlur = (field) => {
    setEditingField(null);
    if (field === "creators" || field === "author") {
    } else {
      setArticleData((prevData) => ({
        ...prevData,
        [field]: editingValue,
      }));
    }
  };

  const handleInputChange = (value) => {
    setEditingValue(value);
  };

  const addAuthor = (position) => {
    const newAuthor = {
      firstName: "",
      lastName: "",
      creatorType: "author",
    };

    setAuthors([
      ...authors.slice(0, position),
      newAuthor,
      ...authors.slice(position),
    ]);
  };

  const removeAuthor = (index) => {
    const newAuthors = [...authors];
    newAuthors.splice(index, 1);
    setAuthors(newAuthors);
    setArticleData((prevData) => ({ ...prevData, creators: newAuthors }));
  };

  const handleAuthorChange = (index, field, value) => {
    const newAuthors = [...authors];
    newAuthors[index][field] = value;
    setAuthors(newAuthors);
  };

  const handleDateChange = (field, val) => {
    if (field === "date") {
      setDate(val);
    } else {
      setAccessDate(val);
    }
    setArticleData((prevData) => ({ ...prevData, [field]: val }));
  };

  const handleAuthorBlur = () => {};

  const checkRequiredFields = (field) => {
    if (field === "creators") {
      return (
        requiredFields.includes(field) && !authors[0].firstName && !editingValue
      );
    }
    return (
      requiredFields.includes(field) && !articleData[field] && !editingValue
    );
  };

  const RenderEditableField = (field) => {
    let required = checkRequiredFields(field);
    return field === "creators" ? (
      <Box key={field} tabIndex={-1} onBlur={handleAuthorBlur}>
        {authors.map((author, index) => (
          <Box mt={2} mb={2} key={`${field}-${index}`}>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent="space-between"
              gap={2}
            >
              <TextField
                value={
                  editingField === "firstName" && index === authorIndex
                    ? editingValue
                    : author.firstName
                }
                onFocus={() => {
                  setAuthorIndex(index);
                  setEditingField("firstName");
                  setEditingValue(author.firstName);
                }}
                onBlur={(e) =>
                  handleAuthorChange(index, "firstName", editingValue)
                }
                onChange={(e) => handleInputChange(e.target.value)}
                label="First Name"
                sx={{
                  borderColor: required ? "red" : "",
                }}
                error={required}
                helperText={required ? "This field is required" : ""}
              />
              <TextField
                value={
                  editingField === "lastName" && index === authorIndex
                    ? editingValue
                    : author.lastName
                }
                onFocus={() => {
                  setAuthorIndex(index);
                  setEditingField("lastName");
                  setEditingValue(author.lastName);
                }}
                onBlur={(e) =>
                  handleAuthorChange(index, "lastName", editingValue)
                }
                onChange={(e) => handleInputChange(e.target.value)}
                label="Last Name"
              />
              <AddIcon
                onClick={() => addAuthor(index + 1)}
                sx={{ cursor: "pointer", mt: 1 }}
              />
              <DeleteIcon
                onClick={() => {
                  if (index !== 0) {
                    removeAuthor(index);
                  }
                }}
                sx={{
                  cursor: index === 0 ? "default" : "pointer",
                  color: index === 0 ? "gray" : "inherit",
                  mt: 1,
                }}
              />
            </Stack>
          </Box>
        ))}
      </Box>
    ) : field === "date" || field === "accessDate" ? (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ mb: 2 }}
          label={field === "date" ? "Date" : "Access Date"}
          value={field === "date" ? date : accessDate}
          onChange={(val) => handleDateChange(field, val)}
        />
      </LocalizationProvider>
    ) : (
      <TextField
        key={field}
        value={editingField === field ? editingValue : renderFieldValue(field)}
        onFocus={() => {
          setEditingField(field);
          setEditingValue(renderFieldValue(field));
        }}
        onBlur={() => handleBlur(field)}
        label={fields[field]}
        sx={{
          borderColor: required ? "red" : "",
          mb: 2,
        }}
        error={required}
        helperText={required ? "This field is required" : ""}
        onChange={(e) => handleInputChange(e.target.value)}
      />
    );
  };

  useEffect(() => {
    if (articleData && authors) {
      const articleDataChanged =
        JSON.stringify(articleData) !== JSON.stringify(originalArticleData);
      const authorsChanged =
        JSON.stringify(authors) !== JSON.stringify(originalAuthors);
      const isDataModified = articleDataChanged || authorsChanged;

      setDisable(!isDataModified);
      fetchCitation(fields, isDataModified);
    }
  }, [selectedBookmarkOption, articleData, authors, selectedStyle]);

  if (selectedBookmarkOption !== "citation") {
    return <></>;
  }
  if (loading) {
    return (
      <Stack direction="column" gap={2} p={2}>
        <Skeleton variant="rectangular" height={118} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />{" "}
      </Stack>
    );
  }

  return (
    <Stack gap={2} p={4}>
      {citation && <CitationBox />}

      <TextField
        select
        label="Style Type"
        value={selectedStyle}
        onChange={handleSelectStyleType}
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
      >
        {citationStyles?.map((style) => (
          <MenuItem key={style["key"]} value={style["key"]}>
            {style["name"]}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Item Type"
        value={currentItemType}
        onChange={handleSelectItemType}
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
      >
        {itemTypes.map((style) => (
          <MenuItem key={style["key"]} value={style["key"]}>
            {style["name"]}
          </MenuItem>
        ))}
      </TextField>
      {Object.keys(fields).map((field) => RenderEditableField(field))}
    </Stack>
  );
};

export default Citation;
